/* Provide sufficient contrast against white background */
body {
  background: #f0ebd8 none repeat center top;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #FFF;
}

.navbar {
  background: #5f2c0d none repeat center center;
  color: #FFF;
}

.text-dark, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #FFF !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.ql-editor {
  min-height: 20vh;
}

.form-group > button {
  z-index: 1;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: white;
  padding: 0.5em 1em;
  outline: none;
  border: none;
  background-color: #b30738;
}

.form-group > button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #b30738;
  transform-origin: center top;
  transform: scaleY(0);
  transition: transform 0.25s ease-in-out;
}

.form-group > button:hover {
  cursor: pointer;
}

.form-group > button:hover::before {
  transform-origin: center bottom;
  transform: scaleY(1);
}

.form-row.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}
@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(-2%);
	}			
	100% {
		transform: translateY(0%);
	}		
}


.checkbox {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: .75rem;
  cursor: pointer;
  appearance: none;
  outline: 0;
  border-color: #b30738;
}
  .checkbox::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;  
    /*border: 2px solid #03a9f4;*/
    border: 2px solid #b30738;
    transition: all 0.3s ease-in-out;
  }

  .checkbox:checked::before {
    height: 50%;
    
    transform: rotate(-45deg);
    
    border-top-style: none;
    border-right-style: none;
  }

  

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.MuiTableCell-body .MuiFormControl-root {
  width: 100%;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input {
  padding: 6.5px 4px !important;
}

.form-group > .MuiInputBase-root > .MuiSelect-root {
  padding: 10.7px 12px !important;
}

#country-select-demo {
  padding-top: 5px;
}

.bootstrap-select {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.bootstrap-select>select.bs-select-hidden, select.bs-select-hidden, select.selectpicker {
  display: block !important;
}