a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1880px !important;
  }
}


.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
